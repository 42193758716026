import SelectSimple from 'Components/public/SelectSimple.js';
import { Lang } from 'Utils';

class SelectHumanRole extends SelectSimple {

  static get properties() {
    return {
      search: { type: Boolean },
      class: { type: String },
    }
  }

  constructor() {
    super();
    this.items = [
      { _id:'pdg', name:{ fr: 'Président Directeur Général (PDG)', en:'Chief Executive Officer (CEO)' } },
      { _id:'dsi', name:{ fr: 'Directeur des Systèmes d\'Information (DSI)', en:'Chief Information Officer (CIO)' } },
      { _id:'rssi', name:{ fr: 'Responsable de la Sécurité des Systèmes d\'Information (RSSI)', en:'Chief Information Security Officer (CISO)' } },
      { _id:'rsi', name:{ fr: 'Responsable Sécurité Informatique (RSI)', en:'Information Security Manager (ISM)' } },
      { _id:'dpo', name:{ fr: 'Délégué à la Protection des Données (DPO)', en:'Data Protection Officer (DPO)' } },
      { _id:'socm', name:{ fr: 'Responsable SOC', en:'SOC Manager' } },
      { _id:'anals1', name:{ fr: 'Analyste SOC N1', en:'N1 SOC Analyst' } },
      { _id:'anals2', name:{ fr: 'Analyste SOC N2', en:'N2 SOC Analyst' } },
      { _id:'rcsirt', name:{ fr: 'Responsable du CSIRT', en:'CSIRT Manager' } },
      { _id:'analis', name:{ fr: 'Analyste réponse aux incidents de sécurité', en:'Security Incident Response Analyst' } },
      { _id:'analmc', name:{ fr: 'Analyste de la menace cybersécurité', en:'Cybersecurity Threat Analyst' } },
      { _id:'sa', name:{ fr: 'Architecte Sécurité', en:'Security Architect' } },
      { _id:'irm', name:{ fr: 'Incident Response Manager (IRM)', en:'Incident Response Manager (IRM)' } },
      { _id:'sco', name:{ fr: 'Responsable de la Conformité Sécurité (SCO)', en:'Security Compliance Officer (SCO)' } },
      { _id:'pen', name:{ fr: 'Pentesteur', en:'Pentester' } },
      { _id:'devsec', name:{ fr: 'DevSecOps', en:'DevSecOps' } },
      { _id:'learn', name:{ fr: 'Apprenant', en:'Learner' } },
    ];
    this.itemTitleField = 'name';
  }

  displayFieldsValue(item) {
    return Lang.lookup(item,'name');
  }

}

customElements.define('select-human-role', SelectHumanRole);