import SelectSimple from 'Components/public/SelectSimple.js';
import { Lang } from 'Utils';

class SelectSeverity extends SelectSimple {
  constructor() {
    super();
    this.items = [
      { _id: 'P0', name: { fr: 'P0 - Indéterminé',     en: 'P0 - Undetermined' } },
      { _id: 'P1', name: { fr: 'P1 - Critique',        en: 'P1 - Critical' } },
      { _id: 'P2', name: { fr: 'P2 - Majeur',          en: 'P2 - Major' } },
      { _id: 'P3', name: { fr: 'P3 - Modéré',          en: 'P3 - Moderate' } },
      { _id: 'P4', name: { fr: 'P4 - Mineur',          en: 'P4 - Minor' } },
      { _id: 'P5', name: { fr: 'P5 - Sans incidence',  en: 'P5 - No impact' } },
    ]
    this.itemTitleField = 'name';
  }

  displayFieldsValue(item) {
    return Lang.lookup(item,'name');
  }

}

customElements.define('select-severity', SelectSeverity);